import React, { useState, useContext, useRef } from 'react';
import { ControlledEditor } from "@monaco-editor/react";
import ThemeContext from '../theme/ThemeContext.js';
import { guessLanguage as doGuessLanguage } from './text-lang-utils.js';

let typingTimer;

export default function MonacoEditor({
        initialContents = '',
        initialLanguage = null,
        onTextChange,
        onLangChange,
        guessEditorLang
    }) {
    const initialLanguageDefaulted = initialLanguage ? initialLanguage : 'plaintext';
    const [theme] = useContext(ThemeContext);
    const [contents, _setContents] = useState(initialContents);
    const [lastChange, setLastChange] = useState('');
    const editorRef = useRef();
    
    const guessLanguage = (text) => {
        const lang = doGuessLanguage(text);
        console.log(`setting editor language to: ${lang}`);
        window.monaco.editor.setModelLanguage(editorRef.current.getModel(), lang);
        onLangChange(lang);
    };

    // ONLY guess editor lang on paste. If a user is manually typing, they can manually adjust the language.
    // there was a bug before where a user starts editing some json in the editor, and then suddenly the
    // language changes to plaintext (while they are still editing), and the cursor jumps to position 0. Bad
    // user experience.
    const editorDidMount = (editor) => {
        guessLanguage(contents);

        editor.onDidPaste((e) => {
            console.log('paste event!');
            const text = editor.getValue();
            setContents('', text);
            if (guessEditorLang) {
                // clearTimeout(typingTimer);
                guessLanguage(text);
            }
            // old code for guessing lang while typing:
            // const shouldGuessLang = guessEditorLang && !([' ', '\n'].includes(lastChange));
            // if (shouldGuessLang) {
            //     typingTimer = setTimeout(() => guessLanguage(contents), 1000);
            // }
        });
    };

    const setContents = (changedText, text) => {
        setLastChange(changedText);
        onTextChange(text);
        _setContents(text);
        if (text.trim().length === 0) {
            // reset back to plaintext as a convenience, since we're no longer guessing as user types
            guessLanguage(text);
        }
        const editor = editorRef.current;
        // height values are in px
        const contentHeight = editor.getContentHeight();
        const containerHeight = editor.getDomNode().style.height.slice(0, -2);
        const isVerticalScrollbarVisible = contentHeight > containerHeight;
        // only let editor capture scroll events if a scrollbar is needed on the editor
        editor.updateOptions({ alwaysConsumeMouseWheel: isVerticalScrollbarVisible });
    };

    return (
        <>
        <div className="ShortAndFullWidth">
            <ControlledEditor
                theme={theme}
                language={initialLanguageDefaulted}
                value={contents}
                onChange={ (ev, value) => setContents(ev.changes[0].text, value) }
                loading={"Loading..."}
                editorDidMount={ (_, editor) => { editorRef.current = editor; editorDidMount(editor); }}
                options={{
                    minimap: { enabled: false },
                    contextmenu: false,
                    cursorSmoothCaretAnimation: true,
                    formatOnPaste: true,
                    links: false,
                    quickSuggestions: true,  // 24/7 intellisense as you type
                    scrollBeyondLastLine: false,
                    smoothScrolling: true,
                    wordWrap: 'on',
                    wrappingIndent: 'same',
                    scrollbar: {
                        // this is toggled programmatically in setContents()
                        alwaysConsumeMouseWheel: false
                    }
                }}
            />
        </div>
        <hr className="MuiDivider-root"/>
        </>
    );
};
