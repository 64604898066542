import yaml from 'js-yaml';
import xmlParser from 'fast-xml-parser';
import marked from 'marked';

export function guessLanguage(text) {
    console.log('Guessing editor language.');
    if (!text) {
        return 'plaintext';
    }
    text = text.trim();
    const isAtLeast2Lines = text.match(/.*\n\S+/);

    // NOTE: in theory, a YAML parser can understand JSON, but not necessarily the other way around.

    // JSON - https://stackoverflow.com/a/52799327
    if (hasJsonStructure(text)) {
        return 'json';
    }

    // HTML
    if (isHtmlLike(text)) {
        return 'html';
    }

    // check for at least 2 lines, because
    if (isAtLeast2Lines && isYaml(text)) {
        return 'yaml';
    }

    // XML - https://github.com/NaturalIntelligence/fast-xml-parser
    if(xmlParser.validate(text) === true) {
        return 'xml';
    }

    if (isAtLeast2Lines && isMarkdown(text)) {
        return 'markdown';
    }

    return 'plaintext';
}

function hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

function isHtmlLike(str) {
    if (!str) {
        return false;
    }
    const endIdx = str.length > 300 ? 300 : str.length;
    const smallSubstring = str.substring(0, endIdx);
    return smallSubstring.match(/(<!doctype html>)?.*<html/i);
}

function isMarkdown(str) {
    // MARKDOWN - https://marked.js.org/#/USING_PRO.md#parser
    try {
        const lexer = new marked.Lexer({});
        const tokens = lexer.lex(str);
        return iterateTokens(tokens);
    } catch (e) {}
    return false;
}

function isYaml(str) {
    // YAML - https://github.com/nodeca/js-yaml#safeloadall-string--iterator--options-
    try {
        const options = { schema: 'FAILSAFE_SCHEMA' };
        yaml.safeLoad(str, options);
        return true;
    } catch (e) {}
    return false;
}

function iterateTokens(tokens) {
    if (!tokens || tokens.length === 0) {
        return false;
    }

    const tokensToIgnore = new Set();
    tokensToIgnore.add('text');
    tokensToIgnore.add('paragraph');
    tokensToIgnore.add('space');

    for (const t of tokens) {
        if (!tokensToIgnore.has(t.type)) {
            return true;
        }
        if (t.tokens) {
            const isMarkdown = iterateTokens(t.tokens);
            if (isMarkdown) {
                return true;
            }
        }
    }
    return false;
}