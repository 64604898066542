import React, {useContext} from "react";
import { Button, Tooltip } from 'antd';
import { MoonIcon, SunIcon } from '../icons/ThemeIcons.js';
import ThemeContext from "./ThemeContext.js";


const ThemeToggle = () => {
    const[theme, setTheme] = useContext(ThemeContext);

    const isLightMode = theme === 'light';
    const modeLabel = isLightMode ? 'Light' : 'Dark';
    const icon = isLightMode ? <SunIcon /> : <MoonIcon />;

    // onMouseDown logic fixes a chrome bug where the button's border remains active/highlighted
    // after the button is clicked and the cursor moves away from the button.
    return(
        <Tooltip title={modeLabel}>
            <Button shape="circle"
                    icon={icon}
                    onMouseDown={ (e) => e.preventDefault() }
                    onClick={ () => setTheme(theme === "light"? "dark": "light") }/>
        </Tooltip>
    );
};

export default ThemeToggle;