import React from 'react';
import { Link } from 'react-router-dom';


export default function Home() {
    return (
        <>
            <br></br>
            <li style={{ marginLeft: "10px" }}><Link to="/base64/encoder">Base64 Encode / Decode</Link></li>
        </>
    );
};