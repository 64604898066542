import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { Switch, Route, withRouter } from "react-router-dom";

import _each from "lodash/each";
import _map from "lodash/map";
const { TabPane } = Tabs;


const RoutedTabs = ({ history, match, tabsProps, routeConfigs, defaultRouteConfig, extraTabBarContents, onChange }) => {
    const { url, path } = match;
    const tabToRouteMap = {};
    const routeToTabsMap = {};
    _each(routeConfigs, (configObj, routeKey) => {
        const routeURL = configObj.getRoute(url);
        tabToRouteMap[routeKey] = routeURL;
        routeToTabsMap[routeURL] = routeKey;
    });
    const defaultActiveKey = '' + routeToTabsMap[history.location.pathname];
    const tabPaneNodes = _map(routeConfigs, (configObj, routeKey) => (
        <TabPane tab={configObj.label} key={routeKey} />
    ));
    const routeNodes = _map(routeConfigs, (configObj, routeKey) => {
        if (configObj.renderComponent) {
            return (
                <Route
                    path={configObj.getRoute(path)}
                    exact
                    key={routeKey}
                    render={configObj.renderComponent}
                />
            )
        } else {
            return (
                <Route
                    path={configObj.getRoute(path)}
                    exact
                    key={routeKey}
                    component={configObj.component}
                />
            )
        }
    });
    if (defaultRouteConfig) {
        const routeKey = routeConfigs.length + 1;
        routeNodes.push(<Route key={routeKey} component={defaultRouteConfig.component} />);
    }

    const onTabChange = activeKey => {
        history.push(tabToRouteMap[activeKey]);
        onChange(tabToRouteMap[activeKey]);
    };

    return (
        <>
        <Tabs {...tabsProps}
            onChange={onTabChange}
            defaultActiveKey={defaultActiveKey}
            tabBarExtraContent={extraTabBarContents}>
            {tabPaneNodes}
        </Tabs>
        <Switch>{routeNodes}</Switch>
        </>
    );
};

RoutedTabs.propTypes = {
    tabsProps: PropTypes.object, // As per https://ant.design/components/tabs/#Tabs
    routeConfigs: PropTypes.array.isRequired,
    defaultRouteConfig: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(RoutedTabs);