import './style/App.css';
import createPersistedState from 'use-persisted-state';
import React, { useEffect } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import ThemeToggle from './components/theme/ThemeToggle.js';
import ThemeContext from './components/theme/ThemeContext.js';
import PullLeftRight from './components/PullLeftRight.js';

import Home from './components/Home.js';
import Base64 from './components/base64/Base64Container.js';

const urlBase64 = 'base64';
const breadcrumbNameMap = {
    [`/${urlBase64}`]: 'Base64 Encoding',
    [`/${urlBase64}/encode`]: 'Base64 Encoder',
    [`/${urlBase64}/decode`]: 'Base64 Decoder',
};

// withRouter() is necessary when creating a header, Header component requires location and other props.
const Header = withRouter(props => {
    const { location, history } = props;
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.filter(p => p.trim().length > 0).map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        if (!breadcrumbNameMap[url]) {
            return null;
        }
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{breadcrumbNameMap[url]}</Link>
            </Breadcrumb.Item>
        );
    });
    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
    ].concat(extraBreadcrumbItems);

    const Routes = () => {
        // each time a route is rendered, generate it's document title
        useEffect(() => {
            const title = history.location.pathname;
            const prettyTitle = title === '/'
                ? `Home`
                : title.split('/').map((v) => capitalize(v)).reduce((a, b) => `${a} ${b}`);
            document.title = prettyTitle;
        });
        return (
            <Switch>
                <Route path={`/${urlBase64}`} component={Base64} />
                <Route path="/" component={Home} />
            </Switch>
        );
    };
    const leftSideComponent = pathSnippets.length === 0 ? <h2>Software Engineering Tools</h2> : <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
    return (
        <>
            <PullLeftRight 
                leftSide={leftSideComponent}
                rightSide={<ThemeToggle />}
            />
            <Routes />
        </>
    );
});

function capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function App() {
    const defaultTheme = 'light';  // light or dark
    const themeHook = createPersistedState('theme')(defaultTheme);
    return (
      <ThemeContext.Provider value = {themeHook}>
          <div className="App">
              <Header />
          </div>
      </ThemeContext.Provider>
    );
}

export default App;
