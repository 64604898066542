import React, { useState, useMemo } from 'react';
import { PageHeader, Drawer, Button } from 'antd';
import RoutedTabs from '../RoutedTabs.js';
import Base64EncoderDecoder from './Base64EncoderDecoder.js';
import { LangContext } from '../editor/LangContext.js';
import KeyValueDropdown from '../dropdown/KeyValueDropdown.js';
import { Base64 } from 'js-base64';  // https://github.com/dankogai/js-base64
import { modes } from './base64-mapping-modes.js';
import { QuestionOutlined } from '@ant-design/icons';


const languageKvMappings = {
    plaintext: 'Plaintext',
    json: 'JSON',
    yaml: 'YAML',
    xml: 'XML',
    html: 'HTML',
    markdown: 'Markdown'
};

export default function Base64Container({ location }) {
    const currentPath = location.pathname;

    const [encodeLang, setEncodeLang] = useState('plaintext');
    const [decodeLang, setDecodeLang] = useState('plaintext');
    const [activeRoute, setActiveRoute] = useState(currentPath.endsWith('decoder') ? 'decoder' : 'encoder');

    const providerValue = useMemo(() => ({encodeLang, setEncodeLang, decodeLang, setDecodeLang}), [encodeLang, setEncodeLang, decodeLang, setDecodeLang]);
    
    const encoderRoute = {
        label: "Encoder",
        renderComponent: () => <Base64EncoderDecoder base64MappingFunc={(value) => Base64.encode(value)} operationDesc="encode" formattingMode={modes.FORMAT_MODE_EDITOR_CONTENTS} />,
        getRoute: path => `${path}/encoder`
    };
    const decoderRoute = {
        label: "Decoder",
        renderComponent: () => <Base64EncoderDecoder base64MappingFunc={(value) => Base64.decode(value)} operationDesc="decode" formattingMode={modes.FORMAT_MODE_MAPPED_CONTENTS} />,
        getRoute: path => `${path}/decoder`
    };
    const defaultRoute = currentPath.endsWith('decoder') ? decoderRoute : encoderRoute;

    console.log('selected lang:' + activeRoute === 'decoder' ? decodeLang : encodeLang)

    // /base64/decoder
    const routedTabsWithDropdown = (
        <RoutedTabs routeConfigs={[ encoderRoute, decoderRoute ]}
                    defaultRouteConfig={ defaultRoute }
                    onChange={(routeName) => setActiveRoute(routeName.substring(routeName.lastIndexOf('/') + 1)) }
                    extraTabBarContents={
                    <KeyValueDropdown
                        kvMappings={languageKvMappings}
                        selected={activeRoute === 'decoder' ? decodeLang : encodeLang}
                        onSelection={(lang) => {
                            if (activeRoute === 'encoder') {
                                setEncodeLang(lang);
                            } else {
                                setDecodeLang(lang);
                            }
                        }}
                    />
                    }
        />
    );

    const [helpDrawerVisible, setHelpDrawerVisible] = useState(false);
    
    return (
        <div className="header-div">
            <LangContext.Provider value={providerValue}>
                <PageHeader
                    className="site-page-header"
                    footer={routedTabsWithDropdown}
                >
                    <span>Quickly encode and decode a string using the Base64 format. <Button shape="circle" size="small" icon={<QuestionOutlined />} onClick={() => setHelpDrawerVisible(true)}/>
                    </span>
                </PageHeader>
            </LangContext.Provider>
            <Drawer
                title="Base64 Encoding - Help"
                placement="right"
                closable={false}
                onClose={() => setHelpDrawerVisible(false)}
                visible={helpDrawerVisible}
            >
                <p>
                    The Base64 encoding algorithm transforms binary data (a string, JPEG image, etc.)
                    into an "encoded" string which consists of Latin letters, digits, plus, and slash. This can
                    be useful when saving or transferring binary data between systems. See the <a href="https://en.wikipedia.org/wiki/Base64" target="_blank" rel="noopener noreferrer">Base64 Wikipedia page</a> for more information.
                </p>
                <h3>Encoder</h3>
                <p>
                    The editor is where you enter the <em>string that should be encoded</em> using the Base64 encoding format.
                    The encoded Base64 string is rendered beneath the editor, along with a copy button for your convenience.
                </p>
                <h3>Decoder</h3>
                <p>
                    The editor is where you enter the <em>already encoded Base64 string</em>, which should be decoded.
                    The decoded content is rendered beneath the editor, along with a copy button for your convenience.
                    The decoded data is always shown as a string - other formats are not supported at this time.
                </p>
            </Drawer>
        </div>
    );
};