import React, { useContext, useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MonacoEditor from '../editor/MonacoEditor.js';
import { LangContext } from  '../editor/LangContext.js';
import { useStorageState } from 'react-storage-hooks';
import { modes } from './base64-mapping-modes.js';
import { guessLanguage } from '../editor/text-lang-utils.js';

let localStorageTimer;

export default function Base64EncoderDecoder({ base64MappingFunc, operationDesc, formattingMode }) {
    const [editorValueLs, setEditorValueLs] = useStorageState(
        sessionStorage,
        `base64-${operationDesc}-editor-contents`,
        ''
    );
    const [showCopyTooltip, setShowCopyTooltip] = useState(false);
    let mappedContent = '';
    let initialEditorValue = '';

    // use previous editor value if found in session storage
    if (editorValueLs && editorValueLs.length > 0) {
        initialEditorValue = editorValueLs;
        mappedContent = base64MappingFunc(editorValueLs);
    }

    const doBase64MappingOperation = (string) => {
        clearTimeout(localStorageTimer);
        mappedContent = base64MappingFunc(string);
        localStorageTimer = setTimeout(() => setEditorValueLs(string), 250);
    };

    //const { lang, setLang } = useContext(LangContext);
    const context = useContext(LangContext);
    const lang = context[`${operationDesc}Lang`];
    const setLang = context[`set${operationDesc.charAt(0).toUpperCase()}${operationDesc.slice(1)}Lang`];
    
    const shouldFormatMappedContent = formattingMode === modes.FORMAT_MODE_MAPPED_CONTENTS;

    let mappedRenderContent = mappedContent;
    let mappedCopyClipboardContent = mappedContent;
    if (shouldFormatMappedContent) {
        const lang = guessLanguage(mappedContent);
        if (lang === 'json') {
            var jsonObject = JSON.parse(mappedContent);
            mappedRenderContent = (syntaxHighlightJson(jsonObject, 'light'));
            mappedCopyClipboardContent = JSON.stringify(jsonObject, null, 4);
        }
    }

    return (
        <>
        <MonacoEditor
            initialContents={initialEditorValue}
            onTextChange={v => doBase64MappingOperation(v) }
            initialLanguage={lang}
            onLangChange={ _lang => setLang(_ => _lang) }
            guessEditorLang={formattingMode === modes.FORMAT_MODE_EDITOR_CONTENTS}
        />
        { mappedContent
            ?
            <CopyToClipboard text={mappedCopyClipboardContent}>
                <Tooltip
                    title="Copied!"
                    placement="top"
                    visible={showCopyTooltip}
                    onVisibleChange={ (visible) => { if (visible) setTimeout(() => setShowCopyTooltip(false), 2000)}}>
                    <Button type="primary" onClick={() => setShowCopyTooltip(true)}>Copy</Button>
                </Tooltip>
            </CopyToClipboard>
            : null
        }
        <br/>
        { shouldFormatMappedContent
            ? <pre className="wrapTextCharacters" dangerouslySetInnerHTML={{ __html: mappedRenderContent }} />
            : <span className="wrapTextCharacters">{mappedRenderContent}</span>
        }
        </>
    );
};

function syntaxHighlightJson(json, theme) {
    const themeCssPrefix = theme + "Theme";
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        var cls = `${themeCssPrefix}JsonNumber`;
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = `${themeCssPrefix}JsonKey`;
            } else {
                cls = `${themeCssPrefix}JsonString`;
            }
        } else if (/true|false/.test(match)) {
            cls = `${themeCssPrefix}JsonBoolean`;
        } else if (/null/.test(match)) {
            cls = `${themeCssPrefix}JsonNull`;
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}