import React from 'react';

export default function PullLeftRight({ leftSide, leftSideItems, rightSide, rightSideItems, leftSideItemsClass = '', rightSideItemsClass = '' }) {
    let leftArray = [];
    let rightArray = [];
    if (leftSide) {
        leftArray.push(leftSide);
    }
    leftArray = leftSideItems ? leftArray.concat(leftSideItems) : leftArray;
    if (rightSide) {
        rightArray.push(rightSide);
    }
    rightArray = rightSideItems ? rightArray.concat(rightSideItems) : rightArray;

    return (
        <div className="navBarWrapper">
            <div className="top bar">
                <ul className="left">
                    {leftArray.map((leftItem) => (
                        <li key={`li-${leftItem.key}`} className={leftSideItemsClass}>{leftItem}</li>
                    ))}
                </ul>
                <ul className="right">
                    {rightArray.map((rightItem) => (
                        <li key={`li-${rightItem.key}`} className={rightSideItemsClass}>{rightItem}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}