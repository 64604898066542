import React from 'react';
import { Menu, Dropdown } from 'antd';
import DownOutlined from '@ant-design/icons/DownOutlined'

export default function KeyValueDropdown({ kvMappings, selected, selectionPrefix, selectionSuffix, onSelection }) {
    const keys = Object.keys(kvMappings);
    const _selected = (!selected) ? (keys.length > 0 ? keys[0] : undefined) : selected;

    const menu = (
        <Menu onClick={e => { onSelection(e.key, kvMappings[e.key]); }}>
            {
                keys.map((key, i) => (<Menu.Item key={key}>{kvMappings[key]}</Menu.Item>))
            }
        </Menu>
    );

    const prefix = selectionPrefix ? `${selectionPrefix}` : '';
    const suffix = selectionSuffix ? `${selectionSuffix}` : '';

    return (
        <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={preventDefaultFunc} href="/#">
                {prefix}{kvMappings[_selected]}{suffix} <DownOutlined/>
            </a>
        </Dropdown>
    );
};

function preventDefaultFunc(e) { e.preventDefault(); }